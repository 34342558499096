import type { VehicleTelemetryDiagnosticNotice } from '@data/fms/vehicle/types';
import equal from 'fast-deep-equal';

/**
 * FMS Console アクセス時に通知を出すかどうか
 * @param {VehicleTelemetryDiagnosticNotice[]} diagnosticsNotice
 * @return {boolean}
 */
export const getIsFirstDiagnosticsNoticeNotification = (
  diagnosticsNotice: VehicleTelemetryDiagnosticNotice[],
) => diagnosticsNotice.some((diagnosticNotice) => diagnosticNotice.level > 0);

/**
 * 通知すべき差分を返す
 * @param {VehicleTelemetryDiagnosticNotice[]} prevDiagnosticsNotice
 * @param {VehicleTelemetryDiagnosticNotice[]} currentDiagnosticsNotice
 * @returns {VehicleTelemetryDiagnosticNotice[]}
 */
export const getNotifiableDiagnosticsNoticeDiff = (
  prevDiagnosticsNotice: VehicleTelemetryDiagnosticNotice[],
  currentDiagnosticsNotice: VehicleTelemetryDiagnosticNotice[],
) =>
  currentDiagnosticsNotice
    .filter(
      (current) => !prevDiagnosticsNotice.some((prev) => equal(prev, current)),
    )
    .filter((diagnosticNotice) => diagnosticNotice.level > 0);
