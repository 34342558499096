import type { LanguageResource } from './types';

const en: LanguageResource = {
  // app
  app: {
    about: {
      title: 'About FMS Console',
      release_date: 'Release Date',
    },
  },
  // common
  common: {
    general: {
      name: {
        short: 'Name',
        original: 'Name',
      },
      filter: 'Filter',
      all: 'All',
      data: 'Data',
      domain: 'Domain',
      csv: 'CSV',
      show: 'Show',
      hide: 'Hide',
      type: 'Type',
      list: 'List',
      notification: 'Notification',
      threshold: 'Threshold',
      description: 'Description',
      id: 'ID',
      config: 'Config',
      occurrence: 'Occurrence',
      recovery: 'Recovery',
      information: 'Information',
      detail: 'Detail',
      version: 'Version',
      created_by: 'Created by',
      updated_by: 'Updated by',
      registered_by: 'Registered by',
      times: 'time',
      times_multiple: 'times',
      times_with_count: '{{count}} time',
      times_with_count_plural: '{{count}} times',
      behind: 'Behind',
      ahead: 'Ahead',
      mileage: 'Mileage',
      total: 'Total',
      load: 'Load',
      load_more: 'Load more',
      contents: 'Contents',
      low: 'Low',
      current: 'Current',
      transit_time: 'Transit time',
      message: 'Message',
      score: 'Score',
      not_set: 'Not set',
      change_to: 'Change to {{value}}.',
      updating: 'Updating',
      map: 'Map',
      software: 'Software',
      others: 'Others',
      title: 'Title',
      link: 'Link',
      timezone: 'Timezone',
      summary: 'Summary',
      shortcutkey: 'Shortcut Keys',
      check_document: 'Check the documentation',
    },
    text: {
      none: 'No {{name}}.',
    },
    fms: {
      autoware: 'Autoware',
      project: 'Project',
      driving_environment: 'Driving Environment',
      stop_point: 'Stop Point',
      stop_points: 'Stop Points',
      route: 'Route',
      schedule: 'Schedule',
      task: 'Task',
      place: {
        origin: 'Origin',
        destination: 'Destination',
        allow_goal_modification: 'Goal Modification',
      },
      map: {
        no_stop_points: {
          title: 'No stop points are registered on the map',
          description:
            'Some FMS functions (e.g. schedule registration) are unavailable until stop points have been registered.',
        },
      },
      area_map: 'Area Map',
      area_map_version: '$t(common.fms.area_map) Version',
      pcd_map: 'PCD Map',
      vector_map: 'Vector Map',
      vector_map_builder: 'Vector Map Builder',
    },
    datahub: {
      event: {
        type: {
          shutdown_start: 'Start of Shutdown period',
          shutdown_end: 'End of Shutdown period',
          network_connected: 'Network Connected',
          network_disconnected: 'Network Disconnected',
        },
      },
    },
    action: {
      action: 'Action',
      activate: 'Activate',
      add: 'Add',
      apply: 'Apply',
      archive: 'Archive',
      back: 'Back',
      cancel: 'Cancel',
      click: 'Click',
      close: 'Close',
      create: 'Create',
      change: 'Change',
      confirm: 'Confirm',
      deactivate: 'Deactivate',
      edit: 'Edit',
      delete: 'Delete',
      deploy: 'Deploy',
      deploy_to_vehicle: 'Deploy to vehicle',
      deselect: 'Deselect',
      download: 'Download',
      end: 'End',
      generate: 'Generate',
      get: 'Get',
      input: 'Input',
      launch: 'Launch',
      next: 'Next',
      ok: 'OK',
      open: 'Open',
      prev: 'Prev',
      record: 'Record',
      refresh: 'Refresh',
      register: 'Register',
      reload: 'Reload',
      remove: 'Remove',
      reset: 'Reset',
      restore: 'Restore',
      return: 'Return',
      save: 'Save',
      search: 'Search',
      select: 'Select',
      start: 'Start',
      stop: 'Stop',
      update: 'Update',
    },
    status: {
      status: 'Status',
      available: 'Available',
      not_available: 'Not Available',
      success: 'Success',
      failure: 'Failure',
      failed: 'Failed',
      unknown: 'Unknown',
      complete: 'Complete',
      completed: 'Completed',
      in_progress: 'In progress',
      generating: 'Generating',
      deleted: 'Deleted',
      deleting: 'Deleting',
      deletion_failed: 'Deletion failed',
      no_deployments: 'No deployments',
      never_executed: 'Never executed',
      valid: 'Valid',
      invalid: 'Invalid',
      updatable: 'Updatable',
      non_updatable: 'Non-updatable',
      active: 'Active',
      activating: 'Activating',
      deactivating: 'Deactivating',
      inactive: 'Inactive',
      restoring: 'Restoring',
      archiving: 'Archiving',
      changing: 'Changing',
      registering: 'Registering',
      copied: 'Copied to clipboard',
      undefined: 'Unknown',
      created: 'Created',
      creating: 'Creating',
      creation_failed: 'Creation failed',
      not_created: 'Not created',
    },
    date: {
      date: 'Date',
      time: 'Time',
      date_and_time: 'Date & Time',
      year_and_month: 'Year & Month',
      created_at: 'Creation Date',
      updated_at: 'Updated',
      registered_at: 'Registration Date',
      published_at: 'Published at',
      start_date: 'Start date',
      end_date: 'End date',
      start_date_time: 'Start date & time',
      end_date_time: 'End date & time',
      start_time: 'Start time',
      end_time: 'End time',
      period: 'Period',
      unit: {
        day: {
          short: 'd',
          original: 'day',
          original_plural: '{{count}}days',
        },
        hour: {
          short: 'h',
          original: 'hour',
          original_plural: '{{count}}hours',
        },
        minute: {
          short: 'm',
          original: 'minute',
          original_plural: '{{count}}minutes',
        },
        second: {
          short: 's',
          original: 'second',
          original_plural: '{{count}}seconds',
        },
      },
      invalid: {
        date: 'Invalid date.',
        time: 'Invalid time.',
        year_and_month: 'Invalid year & month.',
      },
    },
    day_of_week: {
      short: {
        monday: 'Mon',
        tuesday: 'Tue',
        wednesday: 'Wed',
        thursday: 'Thur',
        friday: 'Fri',
        saturday: 'Sat',
        sunday: 'Sun',
      },
      original: {
        monday: 'Monday',
        tuesday: 'Tuesday',
        wednesday: 'Wednesday',
        thursday: 'Thursday',
        friday: 'Friday',
        saturday: 'Saturday',
        sunday: 'Sunday',
      },
    },
    attention: {
      select: {
        vehicle: 'Please select a vehicle.',
        date: 'Please select a date.',
      },
      input: {
        empty: ' Please input',
        half_size:
          'Only half-width characters, excluding spaces, can be entered',
        too_short: 'Too short',
        too_long: 'Too long',
        not_match: 'Not a match',
      },
      date: {
        invalid_format: 'Invalid format',
        date_time: {
          start_date_time_after_end_date_time:
            'Start date and time must be set before end date and time.',
          end_date_time_before_start_date_time:
            'End date and time must be set after the start date and time.',
        },
        date: {
          end_date_before_start_date:
            'End date must be set after the start date.',
          within_days:
            'Please specify the time period to be within {{count}} days.',
        },
        time: {
          not_set: 'Time is not set.',
          not_correctly: 'Time is not set correctly.',
          before_current_time: 'Time is set before the current time.',
          after_current_time: 'Time is set after the current time.',
          start_time_after_end_time:
            'Start time must be set before the end time.',
          end_time_before_start_time:
            'End time must be set after the start time.',
          same_time: 'Start and end times are the same.',
          range_over_one: 'Please set within one day.',
          range_over_other: 'Please set within {{count}} days.',
        },
      },
      once_setting: 'Once set, it cannot be changed.',
      mileage: 'Includes distance during manual operation',
    },
    alert: {
      battery: 'Battery is now below {{count}}%',
    },
  },
  api: {
    cause_unknown: 'Cause unknown',
    status: {
      failed: 'Failed to',
      successfully: 'Successfully',
    },
    service_status: '$t(api.status.{{status}}) get {{service_name}} status.',
    auth: {
      get_projects: '$t(api.status.{{status}}) get projects.',
      post_assert: '$t(api.status.{{status}}) get authority.',
      post_authorization: '$t(api.status.{{status}}) get authorization.',
    },
    datahub: {
      get_diagnostics: '$t(api.status.{{status}}) get diagnostic information.',
      get_events: '$t(api.status.{{status}}) get event data.',
      get_vehicles_driven: '$t(api.status.{{status}}) get vehicles.',
      get_manual_overrides:
        '$t(api.status.{{status}}) get manual override information.',
      get_emergencies: '$t(api.status.{{status}}) get emergency information.',
      get_probes: '$t(api.status.{{status}}) get probe data.',
      get_log_files: '$t(api.status.{{status}}) get {{logType}} files.',
      download_syslog_files: '$t(api.status.{{status}}) download Syslog files.',
      get_metrics:
        '$t(api.status.{{status}}) vehicle operating periods and mileage.',
      get_metrics_daily: '$t(api.status.{{status}}) get driving data.',
      get_scan_matching_score:
        '$t(api.status.{{status}}) get scan matching score.',
      get_scan_matching_score_type:
        '$t(api.status.{{status}}) get scan matching score type.',
      get_weather_locations:
        '$t(api.status.{{status}}) get weather information.',
      get_weather_forecasts: '$t(api.status.{{status}}) get weather forecast.',
      get_weather_observation:
        '$t(api.status.{{status}}) get weather observation.',
      get_driving_route_data: '$t(api.status.{{status}}) get driving route.',
      delete_driving_note: '$t(api.status.{{status}}) delete the driving memo.',
      get_driving_note: '$t(api.status.{{status}}) get driving memo.',
      get_driving_notes: '$t(api.status.{{status}}) get driving memos.',
      patch_driving_note: '$t(api.status.{{status}}) edit driving memo.',
      post_driving_note: '$t(api.status.{{status}}) register driving memo.',
      get_timezone: '$t(api.status.{{status}}) get timezone.',
      get_maintenance_log: '$t(api.status.{{status}}) get log.',
      patch_maintenance_log: '$t(api.status.{{status}}) edit log.',
      post_uptime_reset:
        '$t(api.status.{{status}}) reset system operating time.',
    },
    fms: {
      delete_time_table: '$t(api.status.{{status}}) delete time table setting.',
      delete_vehicle: '$t(api.status.{{status}}) delete {{name}}.',
      delete_vehicle_certificate:
        '$t(api.status.{{status}}) delete certificate.',
      delete_environment: '$t(api.status.{{status}}) delete environment.',
      get_active_schedule: '$t(api.status.{{status}}) get current schedule.',
      get_area_maps: '$t(api.status.{{status}}) get area maps.',
      get_area_map_service_quota:
        '$t(api.status.{{status}}) service quota for area map.',
      get_area_map_versions: '$t(api.status.{{status}}) get area map versions.',
      get_catalogs: '$t(api.status.{{status}}) get catalogs.',
      get_calibration_parameter_graph_files:
        '$t(api.status.{{status}}) get calibration parameter graph files.',
      get_editable_vehicle_calibration_parameters:
        '$t(api.status.{{status}}) get editable vehicle calibration parameters.',
      get_environments: '$t(api.status.{{status}}) get driving environment.',
      get_calibration_parameter_detail:
        '$t(api.status.{{status}}) get calibration parameters detail.',
      get_firmware_entry_list:
        '$t(api.status.{{status}}) get firmware entry list.',
      get_lanelets_center_line_points: '$t(api.status.{{status}}) get Lanelets',
      get_places: '$t(api.status.{{status}}) get stop points.',
      get_route: '$t(api.status.{{status}}) get route.',
      get_route_search: '$t(api.status.{{status}}) route search.',
      get_route_search_current_position:
        '$t(api.status.{{status}}) route search.',
      get_schedule: '$t(api.status.{{status}}) get schedule.',
      get_schedules: '$t(api.status.{{status}}) get schedule.',
      get_tasks: '$t(api.status.{{status}}) get tasks.',
      get_time_tables: '$t(api.status.{{status}}) get time tables.',
      get_vehicle: '$t(api.status.{{status}}) get vehicle.',
      get_vehicle_parameters:
        '$t(api.status.{{status}}) get vehicle individual parameters.',
      get_vehicle_parameter_file:
        '$t(api.status.{{status}}) download vehicle individual parameter file.',
      get_vehicles: '$t(api.status.{{status}}) get vehicles.',
      get_web_auto_application_environment:
        "$t(api.status.{{status}}) get driving environment's option",
      connect_websocket: '$t(api.status.{{status}}) connect WebSocket.',
      patch_environment: {
        area_map_version:
          '$t(api.status.{{status}}) change the area map version.',
        name: '$t(api.status.{{status}}) change the driving environment name.',
        description:
          '$t(api.status.{{status}}) change the driving environment description.',
      },
      patch_vehicles: {
        driving_environment:
          '$t(api.status.{{status}}) change driving environment.',
        acceptable_order: '$t(api.status.{{status}}) change acceptable order.',
        can_start:
          '$t(api.status.{{status}}) change departure instruction reception.',
        vehicle_name: '$t(api.status.{{status}}) change vehicle name.',
        initial_position:
          '$t(api.status.{{status}}) change the initial position.',
        usage: '$t(api.status.{{status}}) change the usage setting.',
        in_vehicle_app_federation:
          '$t(api.status.{{status}}) change the use the API from the vehicle setting.',
      },
      patch_schedule: {
        tags: '$t(api.status.{{status}}) change service type.',
      },
      patch_web_auto_application_environment: {
        enable_battery_alarm:
          '$t(api.status.{{status}}) changed battery notification.',
        battery_alarm_threshold:
          '$t(api.status.{{status}}) changed battery threshold.',
        enable_weather_forecast_notification:
          '$t(api.status.{{status}}) changed precipitation notification.',
        weather_forecast_after_hours_threshold:
          '$t(api.status.{{status}}) changed time threshold.',
        weather_forecast_precipitation_threshold:
          '$t(api.status.{{status}}) changed precipitation threshold.',
        enable_stuck_alarm:
          '$t(api.status.{{status}}) change stuck notification.',
        stuck_alarm_threshold:
          '$t(api.status.{{status}}) change the number of minutes to determine stuck.',
        error_alert_condition:
          '$t(api.status.{{status}}) change the error notification.',
        enable_telemetry_arrival_error_alarm:
          '$t(api.status.{{status}}) changed network notification.',
      },
      patch_vehicle_parameters: {
        description:
          '$t(api.status.{{status}}) changed vehicle individual parameters description.',
      },
      patch_calibration_parameter_settings: {
        enable_application_to_autoware_status:
          '$t(api.status.{{status}}) changed the status use setting applied to Autoware.',
      },
      post_deploy_edge:
        '$t(api.status.{{status}}) accept Web.Auto Agent deploy.',
      post_approve_calibration_parameter_candidates:
        '$t(api.status.{{status}}) approve calibration parameter candidates.',
      post_import_calibration_parameter_candidates:
        '$t(api.status.{{status}}) import calibration parameter candidates.',
      post_create_environment:
        '$t(api.status.{{status}}) create new calibration parameter version with edited parameters.',
      post_editable_calibration_parameter_configs:
        '$t(api.status.{{status}}) create driving environment.',
      post_firmware_rollback:
        '$t(api.status.{{status}}) accept firmware rollback.',
      post_fsim_vehicle_launch: '$t(api.status.{{status}}) start FSim.',
      post_fsim_vehicle_shutdown: '$t(api.status.{{status}}) stop FSim.',
      post_generate_lanelets_center_line_points:
        '$t(api.status.{{status}}) generate Lane data.',
      post_maintenance_schedule:
        '$t(api.status.{{status}}) register maintenance schedule.',
      post_maps_update: '$t(api.status.{{status}}) accept map update.',
      post_restore_area_map_version:
        '$t(api.status.{{status}}) area map version restore request.',
      post_schedule_cancel: {
        successfully_check_page:
          'Schedule cancellation process has started.\nYou can check the latest status on the Schedule List page.',
        successfully: 'Schedule cancellation process has started.',
        failed: '$t(api.status.failed) cancel schedule.',
      },
      post_schedule_register: '$t(api.status.{{status}}) register schedule.',
      post_software_update:
        '$t(api.status.{{status}}) accept the software update.',
      post_task_complete:
        '$t(api.status.{{status}}) instruct manual operation to end.',
      post_task_skip: {
        successfully:
          '$t(api.status.successfully) completed the task skip request.',
        failed: '$t(api.status.failed) complete the task skip request.',
      },
      post_task_retry: {
        successfully:
          '$t(api.status.successfully) completed the task retry request.',
        failed: '$t(api.status.failed) complete the task retry request.',
      },
      post_time_tables:
        '$t(api.status.{{status}}) register the time table setting.',
      post_unsafe_reset: {
        all: {
          successfully: 'Schedule reset process has started.\n',
          failed: '$t(api.status.failed) reset schedule.',
        },
        vehicle: {
          successfully: 'Started the schedule reset process for {{name}}.\n',
          failed: '$t(api.status.failed) reset schedule of {{name}}.',
        },
        check_schedule_list_page:
          'You can check the latest status on the Schedule List page.',
      },
      post_vehicle_door_open:
        "$t(api.status.{{status}}) open the vehicle's door.",
      post_vehicle_door_close:
        "$t(api.status.{{status}}) close the vehicle's door.",
      post_vehicle_emergency_stop: '$t(api.status.{{status}}) emergency stop.',
      post_vehicle_register: '$t(api.status.{{status}}) register vehicle.',
      post_vehicle_resume: '$t(api.status.{{status}}) resume vehicle.',
      post_vehicle_snooze_stuck_alarm:
        '$t(api.status.{{status}}) pause stuck notification.',
      post_vehicle_start: '$t(api.status.{{status}}) departure vehicle.',
      post_vehicle_suspend: '$t(api.status.{{status}}) pause vehicle.',
      post_vehicle_parameter_download_url:
        '$t(api.status.{{status}}) get download URL.',
      post_vehicle_parameter_upload_url:
        '$t(api.status.{{status}}) get upload URL.',
      post_vehicle_parameter_deploy:
        '$t(api.status.{{status}}) deploy parameters.',
      post_vehicle_update_autoware_access_mode:
        '$t(api.status.{{status}}) change Autoware Access Mode.',
      post_vehicle_update_remote_control_status:
        '$t(api.status.{{status}}) remote control {{action}}.',
      post_vehicle_update_visualization_status:
        '$t(api.status.{{status}}) visualization {{action}}.',
      post_vehicle_update_rosbag_save_mode:
        '$t(api.status.{{status}}) change bag save mode.',
      post_lane_unlock: '$t(api.status.{{status}}) lane unlock.',
      post_reissue_certificate:
        '$t(api.status.{{status}}) reissue certificate.',
      put_vehicle_parameter_file_upload:
        '$t(api.status.{{status}}) register parameters.',
      // 通行禁止区域設定
      no_entry_zone_setting: {
        get: '$t(api.status.{{status}}) get the No Entry Zone Setting.',
        create: '$t(api.status.{{status}}) created the No Entry Zone Setting.',
        delete: '$t(api.status.{{status}}) deleted the No Entry Zone Setting.',
        update: '$t(api.status.{{status}}) updated the No Entry Zone Setting.',
        apply: {
          successfully: 'Started applying the No Entry Zone Setting.',
          failed: 'Failed to start applying the No Entry Zone Setting.',
        },
        revoke: '$t(api.status.{{status}}) revoked the No Entry Zone Setting.',
      },
      // 通行禁止区域
      no_entry_zone: {
        create: '$t(api.status.{{status}}) created the No Entry Zone.',
        delete: '$t(api.status.{{status}}) deleted the No Entry Zone.',
        update: '$t(api.status.{{status}}) updated the No Entry Zone.',
      },
    },
    maintenance: {
      get_maintenance_events:
        '$t(api.status.{{status}}) get maintenance information.',
    },
  },
  dialog: {
    auth_error: {
      title: 'Authorization error',
      message:
        'An error occurred while getting the authorization token.<br>Please reload the page.',
    },
    dynamically_imported_module_error: {
      title: 'Page load error',
      message:
        "Loading an old file. Please reload the page as a newer version has been released.<br>If you continue to receive this error, clear your browser's cache and reopen it.",
    },
    websocket_error: {
      title: 'WebSocket connection error',
      message:
        'An error occurred while WebSocket connection.<br>Please ensure that the device you are using is connected to the network and then reload the page.',
    },
    other_error: {
      title: 'An error has occurred',
    },
    driving_environment: {
      create: {
        label: {
          name: 'Driving environment name',
          description: 'Driving environment description',
        },
        attention: {
          empty_name: 'Driving environment name is empty.',
          long_name: 'Driving environment name is too long.',
          only_space: 'Driving environment name contains only spaces.',
          long_description: 'Driving environment description is too long.',
        },
      },
      edit: {
        title: 'Edit driving environment information',
      },
      stuck_alarm_config: {
        switch: {
          message: 'Change to <strong>{{state}}</strong>.',
          attention:
            'Need to restart the vehicle for the changes to take effect.',
        },
        threshold: {
          title: 'Stall time threshold setting',
          message:
            'Change the time threshold for vehicle stalling to <strong>{{count}}</strong> minutes.',
        },
      },
      battery_alarm_config: {
        switch: {
          message: 'Change to <strong>{{state}}</strong>.',
        },
        threshold: {
          title: 'Battery threshold',
          message: 'Change to <strong>{{count}}</strong>%.',
        },
      },
      weather_alarm_config: {
        switch: {
          message: 'Change to <strong>{{state}}</strong>.',
        },
        threshold: {
          hours: {
            title: 'Time setting',
            message_one: 'Change to "<strong>{{count}}</strong> hour later".',
            message_other:
              'Change it to "<strong>{{count}}</strong> hours later".',
          },
          precipitation: {
            title: 'Precipitation setting',
            message: 'Change to <strong>{{count}}</strong>mm.',
          },
        },
      },
      create_lane_data: {
        title: 'Create Lane Data',
        message: 'Create lane data.',
      },
      change_area_map_version: {
        attention: {
          initial_position:
            'If the vehicle has an initial position set, it will be canceled.',
          no_entry_zone_setting:
            'The current area map version has a No Entry Zone Setting applied.\nPlease check and change the setting as they are retained in each area map version.',
        },
      },
      delete: {
        message:
          'Once you delete the environment, you will not be able to view your past driving data.',
      },
      no_entry_zone_setting: {
        apply: {
          title: 'Apply Setting',
          message: 'Apply the settings to the current area map version.',
          alert:
            'It may take a few minutes to apply.\nSetting already applied will be revoked.',
        },
        revoke: {
          title: 'Revoke',
          message: 'Revoke settings from the current area map version.',
        },
        save: {
          title: 'Save Setting',
          message: 'Save No Entry Zone Setting',
          alert:
            'To apply the settings, please save and apply them from the settings details page.',
        },
        update: {
          title: 'Update',
          message:
            'Update the name and description of the No Entry Zone Setting.',
        },
        delete: {
          title: 'Delete',
          message: 'Delete this No Entry Zone Setting.',
        },
      },
      no_entry_zone: {
        add: {
          title: 'Update',
          message: 'Add the No Entry Zone.',
        },
        update: {
          title: 'Update',
          message: 'Update the No Entry Zone.',
        },
        delete: {
          title: 'Delete',
          message: 'Delete the No Entry Zone.',
        },
      },
    },
    vehicle: {
      vehicle_name: {
        message: 'Change the vehicle name of "{{name}}".',
      },
      delete: {
        message: 'Delete "{{name}}".',
        caption:
          'If there is an uncompleted schedule, it will not be deleted.<br>You can only delete it when the vehicle is not connected or shutdown.',
      },
      departure: {
        message: 'Ordering a departure.',
      },
      unlock: {
        message: 'Unlock the lanes of all vehicles in the driving environment.',
        caption:
          'If you are using the same area map in another driving environment, the lane lock will also be unlocked.',
      },
      emergency_stop: {
        message: 'Ordering an emergency stop.',
      },
      emergency_stop_all: {
        title: 'Emergency stop for all vehicles',
        message: 'Ordering all vehicles to make an emergency stop.',
      },
      door_open: {
        message: 'Open the vehicle door.',
      },
      door_close: {
        message: 'Close the vehicle door.',
      },
      usage: {
        message: '',
      },
      change_driving_environment: {
        message: 'If the initial position is set, it will be canceled.',
        not_changeable: 'No driving environment that can be changed.',
      },
      map_update: {
        message: 'Update the map for "{{name}}".',
        edge_no_deployments_message:
          'After updating Web.Auto Agent, please update the map.',
      },
      app_update: {
        message: 'Update the {{label}} for "{{name}}".',
        caption: {
          do_not_drive:
            'Do not depart to drive the vehicle until the update is complete.',
          individual_parameter:
            'The software update will delete the parameters deployed in the vehicle. After the software update is completed, deploy the parameters to the vehicle again.',
        },
        latest_firmware: 'Current firmware is the latest.',
        catalog: {
          service_vehicle: 'Service Vehicle',
          bench_vehicle: 'Bench Vehicle',
        },
        force_deploy: 'Web.Auto Agent force deploy',
      },
      certificate: {
        title: 'Vehicle certificate',
        delete: 'Delete',
        reissue: 'Reissue',
        delete_message: 'Delete certificate of "{{name}}".',
        delete_caption:
          'If you delete the certificate, the vehicle will not be available.<br>If you want to use the vehicle again, please replace the certificate after reissuing the certificate.',
        reissue_message: 'Reissue certificate of "{{name}}".',
        reissue_caption:
          'The certificate will be sent to your registered e-mail address.<br>If you want to use the vehicle again, replace the certificate and then force deploy Web.Auto Agent.',
        remarks:
          'Existing certificate must be deleted first to reissue the certificate',
        email: 'Registered e-mail address：{{email}}',
      },
      initial_position: {
        register: {
          message: 'Register "{{name}}" as the initial position.',
        },
        cancel: {
          message: 'Cancel the set initial position.',
        },
      },
      parameter: {
        register: {
          message: 'Compress and register the uploaded files.',
        },
        download: {
          title: 'Download',
          message: 'Download the parameter file.',
        },
        deploy: {
          title: 'Deploy',
          message: 'Deploy the parameters to the vehicle.',
        },
        edit: {
          title: 'Vehicle parameter description',
          message: 'Edit vehicle parameter description',
          long_description: 'Vehicle parameter description is too long.',
        },
        editor: {
          message: 'Register the edited parameters.',
        },
        setting: {
          enable_application_to_autoware_status: {
            title: 'Change of usage settings for status applied to Autoware',
            message: 'Change to "{{value}}".',
          },
        },
        detail: {
          title: 'Vehicle Parameter Details',
          general: 'General',
          files: 'File list',
          base: 'Version ID of the registered source',
          firmware: 'Firmware Release ID at registration',
          version_id: 'Version ID',
          edited: 'Edited Parameter',
          output_file_name: 'Output File Name',
          api_error_message: 'Failed to get detailed information.',
        },
      },
      remote_control: {
        title: 'Change remote control config',
      },
      visualization: {
        title: 'Change visualization config',
        message:
          'This feature is currently under development.\nIt is available, but please note that it may place a heavy load on the CPU.',
      },
      agent_restart: {
        message: 'Web.Auto Agent needs to be restarted.',
      },
      bag_save_mode: {
        title: 'Change bag save mode',
      },
      autoware_access_mode: {
        title: 'Change Autoware Access Mode',
        message: 'Web.Auto Agent needs to be restarted.',
        alert: 'Please verify the operation in a safe environment before use.',
        radio: {
          rosbridge: 'rosbridge Access Mode',
          rclpy: 'Direct Access Mode',
        },
      },
    },
    schedule: {
      register: {
        schedule: {
          message: 'Create schedule.',
        },
        maintenance: {
          message: 'Add maintenance schedule.',
        },
        time_table: {
          message: 'Register the time table settings for {{day}}.',
        },
        no_entry_zone_alert: {
          message:
            'The route search display may differ from the actual driving route due to the application of the No Entry Zone Setting.',
        },
      },
      cancel: {
        schedule: {
          message: 'Cancel schedule.',
        },
        time_table: {
          message: 'Delete the time table settings for {{day}}.',
          related: 'Cancel the related scheduled or in-progress schedule.',
        },
      },
      reset: {
        message:
          "Forcibly resets the vehicle's schedule. After resetting the schedule, be sure to restart the vehicle. Please note that if the restart is not done correctly, the vehicle may behave unexpectedly before performing the reset.",
        count: 'Resetting the schedule for one vehicle.',
        count_plural: 'Resetting the schedule for {{count}} vehicles.',
      },
      reset_route: {
        message: 'Resets the edited route.',
      },
      manual_drive: {
        register: 'Input transit time.',
        cancel: 'Change from manual driving to automatic driving.',
      },
      service_type: {
        change: 'Change to {{service_type}}.',
      },
      task: {
        complete: {
          title: 'Complete manual driving',
          message: 'Complete the manual driving of "{{name}}".',
        },
        skip: {
          title: 'Skip Task',
          message: 'Skip task.',
        },
        retry: {
          title: 'Reapply',
          message: 'Reapply this route..',
        },
      },
    },
    driving_memo: {
      save: {
        message: 'Save a driving memo.',
      },
    },
    analysis: {
      common: {
        display: {
          label: 'Number of items',
          description:
            'You can set the number of markers and lists to be displayed on the map.',
          switch: 'Set the number of displays',
        },
      },
      error: {
        filter: 'Filtering by node',
        target_value: 'Error target value',
      },
      override: {
        filter: 'Filtering',
      },
    },
    vehicle_condition: {
      uptime_reset: {
        message: 'System operation time will become zero.',
      },
    },
  },
  vehicle: {
    name: 'Vehicle name',
    error: 'Error',
    warning: 'Warning',
    override: 'Override',
    product_name: 'Product name',
    vehicle: 'Vehicle',
    vehicles: '$t(vehicle.vehicle) List',
    vehicle_name: '$t(vehicle.vehicle) Name',
    vehicle_login_id: 'Vehicle Login ID',
    stuck: 'Stuck',
    camera: 'Camera',
    manual_override: 'Manual override',
    manual_driving: 'Manual Driving',
    foa: {
      foa: 'MOT/FOA',
      signal_overwrite: 'Signal overwrite',
      speed_operation: 'Speed operation',
      lane_change: 'Lane change',
    },
    ndt_error: 'NDT Error',
    bag_file: 'Bag File',
    syslog: 'Syslog',
    tcpdump: 'tcpdump',
    coredump: 'coredump',
    telemetry: {
      name: 'Telemetry',
      speed: 'Speed',
      status: {
        disconnected: 'Disconnected',
        connected: 'Connected',
        initializing: 'Initializing',
        task_acceptable: 'Waiting for Task',
        ready: 'Ready',
        driving: 'Driving',
        pausing_for_stop_line: 'Pausing for stop line',
        pausing_for_obstacle: 'Pausing for obstacle',
        suspending: 'Pausing',
        stopping: 'Stopping',
        abnormal: 'Abnormal',
        shutdown: 'Shutdown',
        unknown: 'Unknown',
      },
      drive_mode: {
        title: 'Driving State',
        auto: 'Autonomous',
        manual: 'Manual',
      },
      battery: 'Battery',
      battery_not_available: 'Not available',
      diagnostic_notice: {
        level: {
          0: 'OK',
          1: 'WARN',
          2: 'ERROR',
          3: 'STALE',
        },
        hardware_id: 'Hardware ID',
      },
      telemetry_arrival_error: 'Network Alert',
    },
    type: {
      actual: 'Actual Vehicle',
      psim: {
        short: 'PSim',
        name: 'Planning Simulator',
      },
      fsim: {
        short: 'FSim',
        name: 'FMS Simulator',
      },
    },
    edge_deployment_time: 'Web.Auto Agent updated time',
    firmware: {
      firmware_release_id: 'Firmware Release ID',
      firmware_release_name: 'Firmware Version',
      firmware_deployment_time: 'Firmware updated time',
      firmware_deployment_ecu_status: {
        download_error_counts: 'Download error counts',
        download_error_rate: 'Download error rate',
        download_error_rate_unit: 'count/file',
        download_processed_file_size: 'Download processed file size',
        elapsed_time: 'Elapsed time',
        processed_file_size: 'Processed file size',
        processed_number_of_files: 'Processed number of files',
        release_name: 'Firmware Version',
        total_number_of_files: 'Total number of files',
        transfer_rate: 'Transfer rate',
        total_file_size: 'Total file size',
        remaining_time: 'Remaining time',
        remaining_time_status: {
          unavailable: 'Unavailable',
          in_preparation: 'In preparation',
          almost_done: 'Almost done',
        },
      },
    },
    rosbag_save_mode_status: {
      label: 'Bag Save Mode',
      value: {
        unknown: '$t(common.status.unknown)',
        operation: 'Operation',
        always_saving: 'Always Saving',
      },
      failure: 'Change failure',
    },
    departure: 'Departure',
    can_not_start: {
      message:
        'Departure instructions cannot be issued from the FMS because it is set to disabled.',
    },
    emergency_stop: 'Emergency Stop',
    unlock: 'Unlock lane',
    all: 'All Vehicle',
    door: 'Door',
    catalog: {
      name: 'Catalog',
      catalog_id: '$t(vehicle.catalog.name) ID',
      catalog_name: '$t(vehicle.catalog.name) name',
    },
    category: 'Category',
    remote_control: {
      title: 'Remote control',
      description: 'Remote control of Autoware Drive',
    },
    visualization: {
      title: 'Visualization',
      description: 'Visualization displayed on the remote monitoring screen',
    },
    autoware_access_mode: {
      label: 'Autoware Access Mode',
    },
    creation_status: {
      creating: 'Creating',
      creation_failed: 'Creation Failed',
      deleting: 'Deleting',
    },
  },
  // Media
  media: {
    camera: {
      not_connected: 'Camera is not connected',
    },
  },
  // スケジュール
  schedule: {
    schedule_id: '$t(common.fms.schedule) ID',
    type: {
      basic: 'Basic',
      loop: 'Loop',
      time_table: 'Timetable',
      maintenance: 'Maintenance',
    },
    status: {
      todo: 'Todo',
      doing: 'Doing',
      done: 'Completed',
      canceling: 'Canceling',
      canceled: 'Canceled',
      aborting: 'Aborting',
      aborted: 'Aborted',
      disabled: 'Disabled',
      unknown: 'Unknown',
    },
    plan_time: 'Schedule Time',
    plan_start_time: 'Plan Start Time',
    plan_end_time: 'Plan End Time',
    actual_time: 'Actual Time',
    actual_start_time: 'Actual Start Time',
    actual_end_time: 'Actual End Time',
    delay_time: 'Delay',
    duration: 'Duration',
    start_end_time: 'Start end time',
    created_at: 'Created Date',
    task: {
      type: {
        maintenance: 'Maintenance',
        move: 'Move',
      },
      status: {
        aborting: 'Aborting',
        aborted: 'Aborted',
        doing: 'Doing',
        done: 'Completed',
        todo: 'Todo',
        disabled: 'Disabled',
        unknown: 'Unknown',
      },
    },
    tag: {
      service_type: {
        service: 'Service Driving',
        test: 'Test Driving',
      },
      route_name: 'Route Name',
    },
    via_point: {
      label: 'Via Point',
    },
  },
  maintenance: {
    type: {
      scheduled: 'Regular Maintenance',
      unplanned: 'Unplanned Maintenance',
    },
    status: {
      'not started': 'Not Started',
      'in progress': 'In Progress',
      completed: 'Completed',
      canceled: 'Canceled',
    },
    incident_level: {
      label: 'Impact Level',
      critical: 'High Impact',
      high: '$t(maintenance.incident_level.critical)',
      moderate: 'Low Impact',
      low: '$t(maintenance.incident_level.moderate)',
      informational: 'No Impact',
    },
    target_services: 'Target Services',
    last_updated_time: 'Last updated',
  },
  page: {
    top: 'Top',
    vehicle_detail: 'Vehicle Detail',
    vehicle_register: 'Vehicle Register',
    vehicle_management: 'Vehicle List',
    vehicle_condition: 'Vehicle Condition Management',
    schedule_register: 'Create Schedule',
    driving_history: 'Driving History',
    operation_report: 'Operation Report',
    maintenance_schedule: 'Maintenance Schedule',
    schedule_check: 'Schedule List',
    error_detail: 'Error List',
    initial_position: 'Initial Position',
    driving_environment: 'Driving Environment',
    driving_memo: 'Driving Memo',
    plan_actual_variance: 'Plan Actual Variance',
    analysis: 'Analysis',
    settings: 'Settings',
  },
  header: {
    switch_environment: 'Switch Environment',
  },
  // プロジェクト・走行環境選択 ダイアログ
  project_environment_dialog: {
    title: 'Select project and driving environment',
    create_environment: 'Create driving environment',
  },
  top: {
    active_vehicles: 'Active Vehicles',
    error_state_vehicles: 'Error State Vehicles',
    inactive_vehicles: 'Inactive Vehicles',
    detail: 'Detail',
    all_vehicles_emergency_stop_button: 'Apply Emergency Stop to All Vehicles',
    vehicle_info: 'Vehicle Info',
    applied_no_entry_zone_setting: {
      alert: 'There is a No Entry Zone.',
    },
  },
  // 車両詳細
  vehicle_detail: {
    next_schedule: {
      title: 'Next Schedule',
    },
    camera: {
      label: 'Remote Monitoring',
      view_button: 'View',
      available_camera: 'With camera',
      not_available_camera: 'No camera',
    },
    health_check: {
      title: 'Health Check',
    },
    schedule_detail: {
      is_failed_bypass: {
        has_partial_routes:
          'The via point is set, but the route cannot be driven to the destination due to a No Entry Zone.',
        no_has_partial_routes:
          'The destination cannot be reached due to a No Entry Zone on the route.',
      },
      task: {
        skip_retry: {
          disabled_message: {
            schedule_canceling:
              'Cannot operate while the schedule is canceling',
            schedule_aborting: 'Cannot operate while the schedule is aborting',
          },
        },
        skip: {
          disabled_message: {
            not_manual_drive_status:
              'Can be skipped only in the manual driving state',
          },
          status: {
            succeeded: 'Route application is completed',
            in_progress: 'Route-applying',
            failed: 'Failed to apply route',
          },
        },
        retry: {
          button_label: 'Reapply',
          disabled_message: {
            not_manual_drive_status:
              'Can be retried only in the manual driving state',
          },
        },
      },
    },
  },
  // 車両登録ページ
  vehicle_register: {
    cert: 'Certificate mailing address',
    input_title: 'Input vehicle information',
    confirm_title: 'Confirm vehicle information',
    vehicle_name: {
      title: 'Vehicle Name（between 1 and 128 characters）',
    },
    catalog_select: {
      title: 'Catalog',
      unselect: 'None',
    },
    usage: {
      title: 'Usage',
      actual: 'Actual Vehicle',
      simulator: 'Simulation Vehicle',
    },
    in_vehicle_app_federation: {
      title: 'Enable vehicle API usage',
      possible: 'Yes',
      impossible: 'No',
      caption:
        'For security reasons, the vehicle API should be left disabled if in-vehicle application integration is not required. Additionally, inbound HTTP connections to the on-board HTTP server should not be allowed.',
    },
    vehicle_login_id: {
      create: 'Create Vehicle login ID',
      title:
        'Vehicle login ID (half-width characters, between 4 and 128 characters)',
      password: 'Password (more than 8 characters)',
      password_confirm: 'Password Confirm',
    },
    dialog: {
      message: 'Register "{{vehicleName}}".',
    },
  },
  // 初期位置設定ページ
  initial_position: {
    select: 'Initial Position',
  },
  // 車両状態確認ページ
  vehicle_management: {
    schedule_reset: 'Reset selected vehicle schedule',
    tabs: {
      other_driving_environments: 'Other driving environments',
    },
    deleted_certificate: 'Certificate has been deleted.',
    connection: {
      title: 'Connection Status',
      connect: 'Connect',
      disconnect: 'Disconnected',
    },
    acceptable_order: {
      title: 'Allocate',
      all: 'Enabled',
      none: 'Disabled',
      dedicated: 'Dedicated',
    },
    can_start: {
      title: 'Departure',
      enabled: 'Enabled',
      disabled: 'Disabled',
    },
    map_update: {
      title: 'Map Update',
      tooltip:
        'If the map is not up to date, some functions such as train departures will not be available.',
    },
    app_update: {
      title: 'Software Update',
      failure_reason: 'Failure Reason',
      firmware: 'Firmware',
      web_auto_agent: 'Web.Auto Agent',
      ecu_update_status: 'ECU Update status',
    },
    adapi_version: {
      road_shoulder: 'Support to goal modification.',
    },
    vehicle_info_options: {
      title: 'Info / Options',
      info: {
        title: 'Info',
        all_copy: 'All copy',
      },
      options: {
        title: 'Options',
        environment_change: 'Change Driving Environment',
        delete_vehicle: 'Delete Vehicle',
        in_vehicle_app_federation: 'Use the API from the vehicle',
        disabled_autoware_access_mode_tooltip:
          'You can be changed after the Web.Auto Agent environment is built in Greengrass v2.',
      },
    },
    notification: {
      calibration_parameter_deployment:
        'Requires deployment of vehicle individual parameters.',
      calibration_parameter_is_tampered:
        'Parameters deployed on the vehicle were tampered after deployment.',
      calibration_parameter_not_applied_to_autoware:
        'Vehicle individual parameters are not applied to Autoware.',
      no_vehicle_id_found:
        'Vehicle not found.\nIt has been deleted or moved to an inaccessible environment, or there is an error in the Vehicle ID.Please consult the project manager.',
    },
    vehicle_detail_page_link: '$t(page.vehicle_detail) Page',
    vehicle_creation_failed_message:
      'Please delete the vehicle and register it again.',
    certificate_reissue_message: {
      success: 'Certificate was successfully reissued.',
      failure: 'Certificate reissue failed.',
      in_progress: 'Certificate is being reissued.',
    },
  },
  // スケジュール登録ページ
  schedule_register: {
    estimated_time: 'Estimated time',
    first_origin_position: {
      vehicle_position: 'Current Vehicle Position',
      latest_basic_schedule_destination_position:
        'Last scheduled destination ({{name}})',
    },
    time_table: {
      register: '$t(common.action.register) {{day}} setting',
      remove: '$t(common.action.remove) {{day}} setting',
      info: {
        registered: 'Registered',
        editing: 'Editing',
      },
    },
    schedule_type: 'Schedule Type',
    service_type: 'Service Type',
    attention: {
      autoware_not_running: 'Autoware is not running',
      schedule: {
        continuous_same_stop_point: "There's a series of same stop points.",
        only_one_stop_point: 'Only one stop point is registered.',
        over_max_routes:
          'Set the number of routes to {{max_route_count}} or less',
        loop_schedule_todo_or_doing:
          'Cannot register because the loop schedule is scheduled or in progress.',
        maintenance_schedule_todo:
          'Cannot register because the maintenance schedule is scheduled to be implemented.',
        maintenance_schedule_doing:
          'Cannot register because the maintenance schedule is in progress.',
        schedule_canceling:
          'Cannot register because the schedule is being canceled.',
        schedule_aborting:
          'Cannot register because the schedule is being aborted.',
        no_vehicle: 'No vehicles available for schedule registration.',
        invalid_start_time: 'Invalid format of Plan start time.',
      },
    },
    via_point: {
      disabled: {
        not_created_lanelet_tooltip:
          'Lane data for the area map version has not yet been created. Please create it at the Driving Environment page.',
        manual_drive_tooltip: 'Please cancel manual operation registration.',
      },
      reset: 'Reset before edit',
      route_search_failed: 'Route search failed',
      edit_description: {
        base: 'Select the lanes you want to via on the map and add them.\nUp to {{length}} points can be added. Lanes that have already been added cannot be added again.\n',
        no_entry_zone:
          'If there are overlapping No Entry lanes, you can hide them by "Switching Display" on the map.',
      },
    },
    basic: {
      manual_drive: {
        disabled: {
          via_point_tooltip: 'The via point is set.',
        },
      },
    },
    ad_api: {
      unsupported_road_shoulder: {
        stop_point:
          'The stop position can be modified, but the Autoware/AD API for the selected vehicle is not yet supported.',
        dialog:
          "The route includes stops where the stop position can be modified, but the selected vehicle's Autoware/AD API is not yet supported, so the stop position may not be modified.",
      },
    },
  },
  // スケジュール一覧ページ
  schedule_check: {
    caption_one: 'Total {{count}} schedule.',
    caption_other: 'Total {{count}} schedules.',
    not_found: 'No schedule.',
    copy_schedule_id: 'Copy ID',
  },
  // エラー一覧ページ
  error_detail: {
    data: {
      name: 'Name',
      start_time: 'Start time',
      end_time: 'End time',
      hardware_id: 'Hardware ID',
      level: 'Level',
      message: 'Message',
      diag_period: 'Period',
      error_period: 'Error Period',
    },
    condition: {
      emergency: 'Errors that occurred during abnormal conditions',
      emergency_during_auto:
        'All errors that occurred only in autonomous driving state',
      emergency_stopped:
        'Errors caused an emergency stop during autonomous driving state',
    },
    create_driving_memo: 'Create driving memo',
    copy_link_url: 'Copy link URL',
    back_to_list: 'Back to list',
    error_type: {
      select_label: 'Error display condition',
      all: 'All errors',
    },
    load_value: {
      first: 'Load Values',
      more: 'Load more Values',
    },
  },
  // 走行メモページ
  driving_memo: {
    list: {
      occurrence_date_time: 'Occurrence Date',
    },
    category: {
      memo: 'Memo',
      near_miss_accident: 'Near miss accident',
      bug: 'Bug',
      incident: 'Incident',
    },
    prompt:
      'Unsaved changes will be lost if you navigate away from this page. Are you sure you want to proceed?',
    register: {
      to_continue_create_button: 'Save and Continue Creating',
      to_list_create_button: 'Save to List',
      do_not_show_save_dialog: 'Do not show save dialog',
      simple_input: 'Simple input',
      invalid_format_occurrence_date:
        'Invalid format of Occurrence Date or Time.',
    },
    detail: {
      subject: 'Title',
      surroundings: 'Surrounding conditions',
      not_found: 'Memo not found.',
      cargo: {
        label: 'Cargo',
        yes: 'Yes',
        no: 'No',
      },
      date: {
        label: 'Occurrence Date',
      },
      time: {
        label: 'Occurrence Time',
        buttons: {
          set_current_time: 'Current date/time input',
          plus_seconds: '+{{second}} sec',
          minus_seconds: '-{{second}} sec',
        },
      },
      weather: {
        label: 'Weather',
        type: {
          sunny: 'Sunny',
          cloudy: 'Cloudy',
          rainy: 'Rainy',
          snowy: 'Snowy',
          etc: 'Other conditions (Windy, Thunder)',
        },
      },
      history: {
        title: 'Bag & Driving information',
        slider_title: 'Get {{count}} minutes after occurrence time',
      },
    },
    weather: {
      cant_get:
        "Today's weather is not available. It will be displayed from the next day.",
      select_caption:
        'Failed to get weather observation information. Please select from below.',
    },
    attention: {
      not_probe_data_generated:
        'Vehicle position is not displayed due to unmaintained driving data.',
    },
  },
  // 走行環境ページ
  driving_environment: {
    error_notification_setting: {
      title: 'Error notification',
      condition: {
        emergency:
          'Notification of errors that occurred during abnormal conditions',
        emergency_during_auto:
          'Notification of all errors that occurred only in autonomous state',
        emergency_stopped:
          'Notification only of errors that caused in an emergency stop during autonomous state',
      },
    },
    stuck_alarm_config: {
      title: 'Stuck vehicle notification',
      none: 'Unable to get stuck notification settings.',
      threshold: 'Stuck duration time\n(1 - 30 minutes)',
    },
    battery_alarm_config: {
      title: 'Battery notification',
      threshold: 'Threshold (0 - 99%)',
    },
    weather_alarm_config: {
      title: 'Weather forecast notification',
      threshold: {
        time: 'X hours later (1 - 10 hours)',
        precipitation: 'Precipitation above Xmm\n(0.5 - 30mm)',
      },
    },
    network_alarm_config: {
      title: 'Vehicle network alert notification',
    },
    area_map_info: {
      title: 'Area Map',
    },
    current_area_map_version: {
      title: 'Current Area Map Version',
      disable_no_entry_zone_setting:
        'Lane data has not been created yet, so the No Entry Zone Setting cannot be confirmed.',
    },
    area_map_version_history: {
      title: 'Area Map Version History',
      caption_list: {
        quota: {
          description:
            'Latest {{quota}} versions with their release status set to completion are exempt from auto-archiving.',
          tooltip: {
            title: 'About Automatic Archiving',
            description:
              'To conserve resources, FMS automatically organizes unused older versions of data.\nSuch old versions will be moved under the archive section.\nIf you wish to use an archived old version again, please perform restoration.',
          },
        },
        lane_info:
          'When lane data is created, you can specify transit points between routes on the Schedule Register page.',
      },
      tabs: {
        available: 'Available',
        archived: 'Archived',
      },
      table: {
        release_status: 'Release Status',
        lanelets_center_line_points_status: 'Lane Info Status',
        current_version: 'Current Version',
        change_version: 'Set as current version',
        restore_version: 'Restore this version',
        create_lane_data: 'Create',
      },
      tooltip: {
        disable_change_area_map_version:
          'This version is not available in FMS.',
        disable_restoring:
          'Unable to run while the area map version is being restored.',
        disable_create_lane: 'This version cannot be created.',
      },
    },
    delete: {
      title: 'Delete environment',
    },
    no_entry_zone_setting: {
      title: 'No Entry Zone Setting',
      list: {
        name: 'Setting Name',
        count: 'Number of settings',
        zone_count: 'Number of No Entry Zones',
        over_length:
          'No more can be created.\nPlease delete unnecessary settings.',
        has_not_lanelets_info:
          'Lane data for the area map version has not yet been created.',
        action: {
          add: 'Create New',
          copy_and_create_new: 'Copy and Create New',
        },
        disable_delete_message: {
          current_area_map_version_applied:
            'It cannot be deleted because it is applied to the current Area Map Version.',
          others_area_map_version_applied:
            'It cannot be deleted because it is applied to another version.',
          applying: 'It cannot be deleted because it is being applied.',
        },
        message: {
          only_one_application:
            'Only one No Entry Zone Setting can be applied.',
          alert_apply:
            'There is no No Entry Zone Setting applied. Click the "Confirm" button to go to the detailed setting page to apply the setting.',
        },
      },
      status: {
        applying: 'Applying',
        applied: 'Applied',
        application_failed: 'Application Failed',
        not_apply: 'Not Apply',
      },
      detail: {
        not_found: 'No Entry Zone Setting does not exist.',
        applied_message: 'This setting is applied to the area map version.',
        apply_disable: {
          invalid_lane:
            'Not applicable due to No Entry Zone with non-existent lanes.',
          application_failed: 'Cannot apply due to application failure',
        },
        action: {
          apply: 'Applies to current area map version',
          revoke: 'Revoke from current area map version',
          update: 'Updated name and description',
        },
      },
      form: {
        required: 'Please enter',
        over_length: 'Please enter up to {{count}} characters',
      },
      no_entry_zone: {
        title: 'No Entry Zone',
        list: {
          caption: 'Up to 10 entries can be registered',
          not_addable:
            'Only settings that have not yet been applied can be edited for No Entry Zones.',
          not_zero: 'It is not possible to remove all No Entry Zones.',
          action: {
            add: 'Create No Entry Zone',
          },
        },
        category: {
          label: 'Category',
          construction: 'Construction',
          others: 'others',
        },
        time_type: {
          period: 'Periodic',
          spot: 'Spot',
        },
        detail: {
          tabs: {
            form: 'Form',
            select_lane: 'Select Lanes',
          },
          disable_edit: 'Cannot be changed when editing',
          lane_list: {
            caption:
              'Select the lanes you want to prohibit traffic on the map and add them. You can add up to 10 lanes.',
            display_lane: 'Display',
            not_exist: 'This lane does not exist.',
          },
        },
      },
      map: {
        over_lanelets:
          'The total exceeds 10 lanes and cannot be added.\nPlease re-select.',
        multiple_lanelets: 'Add {{count}} lanes.',
        currently_applied_no_entry_zone: 'No Entry',
      },
    },
  },
  // 運行レポートページ
  operation_report: {
    select_date_error_message: 'Please select a month before this month.',
    aggregation_caption: 'The driving information is aggregated hourly.',
    distance_per_error: 'Mileage per error',
    distance_per_override: 'Mileage per override',
    graph: {
      daily_mileage: 'Daily $t(common.general.mileage)',
      daily_error_and_distance_per_error:
        'Number of errors by day and mileage per error',
      daily_override_and_distance_per_override:
        'Number of override by day and mileage per override',
    },
  },
  // 走行履歴ページ
  driving_history: {
    based_on_allocate: 'Dispatch schedule base list',
    driving_time_refine: 'Narrow down by driving time',
    not_found: 'No driving history.',
    filter: {
      task_list: {
        only_task_contain_error_or_override_foa:
          'Only tasks containing Error or Manual override or MOT/FOA',
        only_error_task: 'Only Error tasks',
        only_override_task: 'Only Manual override tasks',
        only_foa_task: 'Only MOT/FOA tasks',
      },
      period: {
        only_bag_contain_error_or_override_foa:
          'Only bags containing Error or Manual override or MOT/FOA',
        only_error_bag: 'Only Error bags',
        only_override_bag: 'Only Manual override bags',
        only_foa_bag: 'Only MOT/FOA bags',
      },
    },
    attention: {
      not_probe_data_generated:
        'Driving route, Error, and override information are not yet in place. Please allow some time and check again.',
      over_100_errors:
        'In case of 100 or more errors, no marker will be displayed.',
      over_100_foa:
        'In case of 100 or more MOT/FOA overrides, no marker will be displayed.',
      date: {
        minimum_duration_under:
          'Start Time and End Time should be no more than 12 hours apart.',
      },
      is_fsim: 'FSim vehicle cannot check for errors, etc.',
    },
    probe_loading: 'Loading the route',
    error_list: {
      event: {
        tooltip: {
          shutdown_start: 'This is not an error.',
          shutdown_end:
            '$t(driving_history.error_list.event.tooltip.start_shutdown)',
          network_connected:
            'This is not an error, but errors during network disconnection will not be retrieved.',
          network_disconnected:
            '$t(driving_history.error_list.event.tooltip.network_connected)',
        },
      },
    },
    foa_list: {
      location_unknown: 'Location unknown',
    },
  },
  // 予実解析ページ
  plan_actual_variance: {
    plan_actual_variance: 'Plan Actual Variance',
    detail_window: {
      route_all_button: 'All route',
      route_detail_button: 'Route detail',
      stuck_detail_button: 'Stay detail',
    },
    graph: {
      variance: 'Plan Actual Ratio',
      stuck: 'Stuck count',
      error: 'Error count',
      ndt: 'NDT Error count',
      driving: 'Driving count',
      settings: {
        medium: 'Medium (%)',
        large: 'Large (%)',
      },
    },
  },
  // 分析
  analysis: {
    graph: {
      mileage: '$t(common.general.mileage) (km)',
    },
    error: {
      graph: {
        type_ratio: 'Node (%)',
        type_count: 'Node (count)',
        count: 'Error Count',
        number_of_errors_per_km: 'Number of errors per km',
        number_of_mileage_per_error: 'Travel distance per error (km)',
      },
      list: 'Error Period',
      csv: {
        button: {
          generate: 'Generate CSV for {{date}}',
          download: 'Download CSV for {{date}}',
        },
        tooltip: {
          no_filter: 'No filtering applied',
          overtime: 'Limit the period to one day',
        },
      },
    },
    override: {
      graph: {
        type_ratio: 'Category (%)',
        type_count: 'Category (count)',
        count: 'Override Count',
        number_of_override_per_km: 'Number of overrides per km',
      },
      list: 'Override list',
    },
    scan_matching_score: {
      label: 'Scan Matching Score',
      score_type: 'Score Type',
      graph: {
        average: 'Scan Matching Score / Daily Average',
      },
    },
  },
  // 地図メニュー
  map_menu: {
    title: 'Switch Display',
    stop_point_names: 'Stop Point Names',
    no_entry_zones: {
      title:
        '$t(driving_environment.no_entry_zone_setting.no_entry_zone.title)',
      all: 'All',
    },
    lanelets_center_points: 'Lanes',
  },
  // 設定
  settings: {
    language: {
      title: 'Language',
      reload_caption: 'Page will reload when you change the language.',
    },
    alert: {
      title: 'Notification',
      auto_close: {
        title: 'Automatically close notifications',
        message: 'Automatically closes in about 10 seconds.',
      },
      sound: {
        title: 'Alert Sound',
      },
    },
    map: {
      title: 'Map',
      check: {
        vehicle_center: 'Always center the vehicle on the map',
        display_current_route: 'Display the current route on the map',
      },
    },
  },
  // 遠隔監視画面
  remote: {
    cameras: {
      no_scope: "You don't have permission to view the camera feed.",
      no_camera: 'No cameras.',
    },
    call: {
      state: {
        calling: 'Calling',
        talking: 'In a call',
      },
    },
    operation: {
      view: {
        label: 'Switch View',
        camera_and_call: 'Camera & Call',
        map_and_viz: 'Map & Viz',
        display_viz: 'Display Viz',
      },
    },
  },
  // 車両状態管理
  vehicle_condition: {
    vehicle_not_found: 'Vehicle not found.',
    list: {
      condition: 'System operating time & Accumulated mileage',
      individual_parameter: 'Individual Parameters',
    },
    parameter: {
      status: {
        is_tampered: {
          label: 'Tampered',
          caption:
            'Parameters deployed to a vehicle have been changed after deployment and are different from those at the time of deployment. Please deploy the parameters to the vehicle again.',
        },
        application_to_autoware_status: {
          applied: 'The current version is applied to Autoware.',
          not_applied:
            'The current version has not been applied to Autoware. Please reboot the vehicle.',
        },
      },
      list: {
        title: 'Registration List',
        disabled_deploy: 'Vehicle is not connected to the FMS',
        version: {
          current: 'Current Version',
          deployment_candidate: 'Deployment candidate Version',
          last_deployed: 'Last deployed Version',
        },
      },
      register: {
        title: 'Register parameters',
        description: {
          caption: 'Max 255 characters',
          error:
            'Characters other than half-width characters have been entered',
        },
        select_directory: {
          label: 'Select directory',
          hide_hidden_files: 'Hide hidden files',
          file_not_found: 'File not found.',
        },
      },
      suggestion: {
        title: 'Candidate parameters',
        item: {
          target: {
            title: 'Calibration target',
            calibration_status: 'Calibration status',
            candidates_status: 'Candidates status',
          },
          status: {
            title: '$t(common.status.status)',
            label: {
              normal: 'Normal',
              nothing: 'Nothing',
              calibration_required: 'Suggested',
              unavailable: 'Suggestion unavailable',
              calibration_required_after_parameter_deployed:
                'Unreflected after parameter deployed',
              importing: 'Importing',
              import_failure: 'Import failure',
              imported: 'Imported',
              waiting_for_approval: 'Waiting for approval',
              approved: 'Approved',
              approval_failure: 'Approval failure',
              undefined: 'Unknown',
            },
            restart: 'Need to restart the vehicle',
            failure: {
              title: 'Reason for failure',
            },
          },
        },
        action: {
          import: 'Import candidate value',
          imported: 'Re-import candidate value',
          confirm: 'Candidate value confirmation',
          dialog: {
            deploy: 'Generate a new version and deploy it in the vehicle',
          },
          attention:
            'Need to restart the vehicle for the changes to the parameters.',
        },
      },
      editor: {
        title: 'Parameter Edit',
        not_editable: 'No editable parameters',
        reload: 'Value has been updated, please reload',
        expanded_capacity: {
          error_message: {
            empty: 'Please input',
            under_min: 'Minimum limit exceeded',
            over_max: 'Maximum limit exceeded',
            not_number: 'Not a number',
            decimal: 'Please enter to {{count}} decimal places',
          },
        },
      },
      setting: {
        enable_application_to_autoware_status: {
          label: 'Enable Apply Status to Autoware',
          tooltip:
            'Notifies Autoware of the application status of parameters deployed to a vehicle, and prohibits route delivery or departure based on the application status to Autoware.\nFor more information, please <docs>click here</docs>.',
          on: 'Enabled',
          off: 'Disabled',
          unsupported:
            'Cannot change the status of Apply to Autoware as it is not supported.',
        },
      },
    },
    condition: {
      caption:
        'System operating time and accumulated mileage are tallied hourly.',
      date: {
        return_latest_button: 'Show latest',
      },
      system_operating_time: {
        label: 'System operating time',
      },
      accumulated_mileage: {
        label: 'Accumulated mileage',
      },
    },
    log: {
      title: 'Vehicle condition log',
      category: {
        label: 'Category',
        type: {
          uptime_reset: 'Reset operating time',
        },
      },
      contents: {
        edit_caption: 'Must be at most 600 characters.',
      },
    },
  },
  // 通知
  notification: {
    clear_all: 'Clear all displayed notifications',
    vehicle_alert_list: {
      title: 'List of Vehicle Notifications',
      clear_all: 'Clear all',
      tooltip:
        'Only those notified when the FMS Console is open will be displayed. The list is reset when the page is closed.',
      none: 'No notifications',
    },
    vehicle_agent_error: {
      document_link: 'Detailed Information',
    },
    weather: {
      precipitation: {
        title: 'Weather Hazard Forecasting',
        message_one:
          '{{precipitation}}mm of precipitation (snow) is expected in about {{count}} hour later.',
        message_other:
          '{{precipitation}}mm of precipitation (snow) is expected in about {{count}} hours later.',
      },
    },
    update_failure: {
      type: {
        mapUpdateFailure: '$t(common.general.map)',
        agentUpdateFailure: '$t(vehicle_management.app_update.web_auto_agent)',
        firmwareUpdateFailure: '$t(vehicle_management.app_update.firmware)',
      },
      title: '$t({{type}}) update failure',
      message:
        "Please check the reason for failure from each\nvehicle's info icon on the vehicle list page.",
    },
    individual_parameter: {
      suggestion: {
        title: 'Candidate values of vehicle-individual parameters',
        message: {
          suggested:
            'Calibration required.\nPlease restart the vehicle after the deploy the new version.',
          imported:
            'Candidate values have already been imported.\nNew versions can be generated and deployed.',
        },
      },
      tampered: {
        title: 'Vehicle Individual Parameters are tampered',
        message:
          'Parameters deployed on the vehicle were modified after deployment.',
      },
    },
    telemetry_arrival_error:
      "The vehicle's telemetry data is not reaching the cloud-side of FMS. Please ensure the vehicle's network connection is working properly. Click <docs>here</docs> for instructions on how to check the network connection.",
    custom_notification: {
      health_check: {
        title: 'Hardware problems',
      },
    },
  },
};

export default en;
